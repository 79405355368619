.stats--container {
  background: #0a0c36;
  color: white;
  height: 100%;
  padding: 8px 10px 8px 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;

  .stats-icon--container {
    background: #1a1c43;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .stats--info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .number {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    .title {
      font-size: 12px;
      opacity: 0.5;
    }
  }
}

@media (max-width: 1199.98px) {
  .stats--container {
    width: 100%;
    display: flex;
  }
}
