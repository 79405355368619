.bottom-bar--container {
  margin-top: 50px;
  .bottom-bar--body {
    margin-bottom: -20px !important;
    width: 100%;
    border-radius: 30px 30px 0 0;
    background-color: #1a1c43;
    padding: 20px 0;
    margin: 0;

    .logo {
      img {
        width: 150px;
      }
    }

    .center {
      color: white;
      font-size: 12px;

      a {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      .divider {
        width: 1px;
        height: 20px;
        background-color: rgba(365, 365, 365, 0.6);
        opacity: 0.5;
      }
    }
  }
}
