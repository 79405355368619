.drawer--link {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 35px;
  opacity: 0.7;
  font-weight: 600;
  position: relative;

  &:hover {
    opacity: 1;
    background: linear-gradient(100deg, #0094ff -10%, #fd4990 110%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.active {
    opacity: 1;
    background: linear-gradient(100deg, #0094ff -10%, #fd4990 110%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.active::before {
    position: absolute;
    content: "";
    width: 50%;
    height: 4px;
    bottom: -1px;
    background: linear-gradient(100deg, #0094ff -10%, #fd4990 110%);
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
  }
}
