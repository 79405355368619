.staking-dropdown--container {
  visibility: hidden;
  opacity: 0;
  transition: display 0s, 0.2s ease-out;
  transform: translateY(-80%);
  z-index: -500;

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    z-index: 0;
  }
}
