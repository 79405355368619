.card--container {
  background: #1a1c43;
  padding: 10px;
  border-radius: 10px;

  .bordify {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(356, 356, 365, 0.1);
    padding: 12px 14px;
    border-radius: 10px;
  }
}
